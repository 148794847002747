// Main

$body-bg:#181818;
$body-color: #d7dbdf;
$card-bg: #080808;
$heading-color: #f3f3f3;
$border-color:#313131;
$secondary: #5c6ca5;
$dark: #60658a;

$alert-bg-scale: 60%;
$alert-border-scale: 0%;
$alert-color-scale: 80%;
