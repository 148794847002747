&{
    --bs-border-color: #{$border-color};
    --bs-body-bg: #{$body-bg};
    --bs-body-color: #{$body-color};
    --heading-color: #{$heading-color};
    --ms-bg: #{$card-bg};
    --ms-border-color: var(--bs-border-color);
    --ms-dropdown-border-color: var(--bs-border-color);
    --ms-dropdown-bg: #{$card-bg};
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    color: var(--heading-color);
}
.border {
    border-color: $border-color !important;
}
.border-bottom {
    border-color: $border-color !important;
}
.datepicker-picker {
    background-color: $card-bg;
    .datepicker-title {
        background-color: $card-bg;
    }
    .datepicker-controls {
        .button {
            background-color: $card-bg;
            color: $body-color;
        }
    }
}
thead, tbody, tfoot, tr, td, th {
    border-color: $border-color;
    color: $body-color;
}

.offcanvas {
    background-color: $card-bg;
    color: $body-color;
}
button {
    &.close-btn-res {
        color: $light;
    }
}
hr.hr-horizontal{
    background-image: linear-gradient(90deg, transparent, rgba($white,0.4), transparent)
}
hr.hr-vertial {
    background-image: linear-gradient(180deg, transparent, rgba($white,0.4), transparent)
}
.text-dark{
    color: $body-color !important;
}
.apexcharts-legend-text{
    color: $body-color !important;
}
.bg-body{
    background-color: $body-bg !important;
}
