.dropdown-menu {
    background-color:var(--#{$variable-prefix}$body-bg);
    color: var(--#{$variable-prefix}$body-color);
    .dropdown-item {
        color: $body-color;
        background-color: transparent;
    }
    
}
.like-block {
    span {
        color: $body-color;
    }
}


.dropdown>.dropdown-menu {
    background-color: $body-bg;
}
.dropdown>.dropdown-menu>a{
    color: $body-color;
}


.dropdown-item{
    &:hover, &:focus{
        color: var(--#{$variable-prefix}primary-shade-20);
        border-radius: $border-radius;
        cursor: pointer;
        background-color: var(--#{$variable-prefix}primary-shade-80);
    }
}